import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/seo";

import "../styles/highlight.scss";

const Highlight = ({ pageContext, data }) => {
  const { highlight, next, prev } = pageContext;

  const highlightMap = {
    "langston-hughes": {
      main: "50% 23%",
    },
    "walt-braithwaite": {
      main: "50% 23%",
    },
    "ann-lowe": {
      more: "50% 10%",
    },
    "moss-kendrix": {
      more: "50% 20%",
    },
    "lauren-simmons": {
      more: "50% 30%",
    },
    "richie-havens": {
      main: "50% 20%",
    },
    "jimi-hendrix": {
      main: "50% 10%",
    },
    "sister-rosetta-tharpe": {
      main: "50% 10%",
    },
    "edward-alexander-bouchet": {
      main: "50% 40%",
    },
    "cathay-williams": {
      main: "50% 15%",
    },
    "mari-copeny": {
      main: "50% 20%",
    },
    "surya-bonaly": {
      main: "50% 30%",
    },
  };
  const style =
    highlightMap[highlight.slug] && highlightMap[highlight.slug].main
      ? { backgroundPosition: highlightMap[highlight.slug].main }
      : {};

  const styleMore =
    highlightMap[highlight.slug] && highlightMap[highlight.slug].more
      ? { backgroundPosition: highlightMap[highlight.slug].more }
      : {};

  return (
    <>
      <SEO
        title={highlight.title}
        image={
          (highlight.images && `https:${highlight.images[0].fixed.src}`) || null
        }
      />
      <LatticeGrid
        className={`highlight ${highlight.slug}`}
        fullWidth
        bgImageData={
          (highlight.images && highlight.images[0].fluid) ||
          data.default.childImageSharp.fluid
        }
        style={style}>
        <div />
        <h1>{highlight.title}</h1>
      </LatticeGrid>
      <LatticeGrid className="highlight-content">
        {highlight.tags ? (
          <div className="tags">
            {highlight.tags.map(tag => (
              <div key={tag} className={`tag tag-${tag.toLowerCase()}`}>
                <span>{tag}</span>
              </div>
            ))}
          </div>
        ) : null}
        {highlight.highlight ? (
          <div
            dangerouslySetInnerHTML={{
              __html: highlight.highlight.childMarkdownRemark.html,
            }}
          />
        ) : null}
        {highlight.link ? (
          <p>
            <a
              href={highlight.link}
              target="_blank"
              rel="noopener noreferrer"
              title={highlight.linkTitle || `${highlight.title} More Info`}>
              {highlight.linkTitle || highlight.link}
            </a>
          </p>
        ) : null}
      </LatticeGrid>
      <LatticeGrid className="highlight-nav" innerGrid>
        {prev ? (
          <Link to={`/${prev.slug}`} className="prev">
            <div>
              <h4>{prev.date}</h4>
              <p>{prev.title}</p>
            </div>
          </Link>
        ) : null}
        {next ? (
          <Link to={`/${next.slug}`} className="next">
            <div>
              <h4>{next.date}</h4>
              <p>{next.title}</p>
            </div>
          </Link>
        ) : null}
      </LatticeGrid>
      {highlight.images && highlight.images.length > 1 ? (
        <LatticeGrid
          className={`highlight-more ${highlight.slug}`}
          fullWidth
          bgImageData={highlight.images[1].fluid}
          style={styleMore}
        />
      ) : null}
    </>
  );
};

Highlight.propTypes = {
  pageContext: PropTypes.shape({
    highlight: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          fluid: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
          fixed: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
        }),
      ),
      highlight: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
      linkTitle: PropTypes.string,
      link: PropTypes.string,
      date: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    next: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      date: PropTypes.string,
    }),
    prev: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      date: PropTypes.string,
    }),
  }).isRequired,
  data: PropTypes.shape({
    default: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Highlight;
export const query = graphql`
  query {
    default: file(relativePath: { eq: "bhm-intro.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
